import './style.scss';
import * as dat from 'dat.gui';
import * as THREE from 'three';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as nipplejs from 'nipplejs';
import groundVertexShader from './shaders/ground/vertex.glsl';
import groundFragmentShader from './shaders/ground/fragment.glsl';
import sunVertexShader from './shaders/sun/vertex.glsl';
import sunFragmentShader from './shaders/sun/fragment.glsl';
import globeVertexShader from './shaders/globe/vertex.glsl';
import globeFragmentShader from './shaders/globe/fragment.glsl';
import buildingVertexShader from './shaders/building/vertex.glsl';
import buildingFragmentShader from './shaders/building/fragment.glsl';

/**
 * Base
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

// GUI folders
const gui = new dat.GUI();
const buildingTweaks = gui.addFolder('buildings');
const groundTweaks = gui.addFolder('ground');
const sunTweaks = gui.addFolder('sun');
const starTweaks = gui.addFolder('stars');
const bloomTweaks = gui.addFolder('bloom');
const cameraTweaks = gui.addFolder('camera');
gui.width = 225;
gui.close();

// Setup & Globals
const canvas = document.querySelector('canvas.webgl');
const scene = new THREE.Scene();

const debugObject = {
  worldSize: 50,
  movementSpeed: 0,
  movementSpeedCap: 200,
  baseMusicVolume: 50,
  sunColor: '#ad4600',
  sunSize: 0.9,
  sunBaseSpeed: 5,
  groundSegments: 35,
  groundSize: 100,
  towerHeight: 5,
  groundHeight: 2,
  groundColor: '#8f00fd',
  buildingGridColor: '#000000',
  buildingLightChangeRate: 1,
  buildingScale: 0.8,
  starColor: '#FFFFFF',
  starSpeed: 1,
  position: 0,
  joystickForce: 1,
  cemeraFOV: 45,
  maxFOV: 125,
  minFOV: 10,
  isFirstMovement: true,
};

/**
 * Music
 */
const pageStyle = document.documentElement.style;
pageStyle.setProperty('--backgroundColor', debugObject.groundColor);

const playPauseButton = document.getElementById('music-play-pause-button');
const audioPlayer = document.getElementById('audio-player');
const playPauseMusic = () => {
  playPauseButton.classList.toggle('pause');
  if (playPauseButton.classList.contains('pause')) {
    audioPlayer.play();
  } else {
    audioPlayer.pause();
  }
  playPauseButton.blur();
};
const muteButton = document.getElementById('mute-button');
muteButton.addEventListener('click', (e) => {
  e.preventDefault();
  audioPlayer.muted = !muteButton.classList.contains('muted');
  muteButton.classList.toggle('muted');
  muteButton.blur();
});

playPauseButton.addEventListener('click', (e) => {
  e.preventDefault();
  playPauseMusic();
});

// Star Globe
const globeGeometry = new THREE.SphereGeometry(debugObject.worldSize * 5, 20, 20);
const globeMaterial = new THREE.ShaderMaterial({
  vertexShader: globeVertexShader,
  fragmentShader: globeFragmentShader,
  side: THREE.BackSide,
  uniforms: {
    uStarSize: { value: 0.01 },
    uColor: { value: new THREE.Color(debugObject.starColor) },
  },
});
const globe = new THREE.Mesh(globeGeometry, globeMaterial);
scene.add(globe);
starTweaks
  .add(globeMaterial.uniforms.uStarSize, 'value')
  .min(0.0)
  .max(2.5)
  .step(0.001)
  .name('size');
starTweaks.add(debugObject, 'starSpeed').min(0.0).max(100).step(0.1)
  .name('base speed');
starTweaks
  .addColor(debugObject, 'starColor')
  .name('color')
  .onChange(() => {
    globeMaterial.uniforms.uColor.value.set(debugObject.starColor);
  });
// Ground Plane
const groundGeometry = new THREE.PlaneBufferGeometry(
  debugObject.worldSize * 3,
  debugObject.worldSize * 3,
  debugObject.groundSize * 5,
  debugObject.groundSize * 5,
);
const groundMaterial = new THREE.ShaderMaterial({
  vertexShader: groundVertexShader,
  fragmentShader: groundFragmentShader,
  side: THREE.DoubleSide,
  uniforms: {
    uPosition: { value: debugObject.position },
    uGroundSize: { value: debugObject.groundSize },
    uTowerHeight: { value: debugObject.towerHeight },
    uGroundHeight: { value: debugObject.groundHeight },
    uColor: { value: new THREE.Color(debugObject.groundColor) },
    uNumSegments: { value: debugObject.groundSegments },
    uLineThickness: { value: 0.05 },
  },
});
const ground = new THREE.Mesh(groundGeometry, groundMaterial);
ground.rotation.x = Math.PI * 0.5;
ground.position.y = -1;
scene.add(ground);

groundTweaks
  .add(groundMaterial.uniforms.uNumSegments, 'value')
  .min(0.0)
  .max(200.0)
  .step(1.0)
  .name('segments');
groundTweaks
  .add(groundMaterial.uniforms.uLineThickness, 'value')
  .min(0.0)
  .max(1.0)
  .step(0.025)
  .name('line thickness');
gui
  .add(debugObject, 'movementSpeed')
  .min(0.0)
  .max(debugObject.movementSpeedCap)
  .step(1)
  .name('speed')
  .listen();
groundTweaks
  .addColor(debugObject, 'groundColor')
  .name('line color')
  .onChange(() => {
    pageStyle.setProperty('--backgroundColor', debugObject.groundColor);
    groundMaterial.uniforms.uColor.value.set(debugObject.groundColor);
  });
gui.add(debugObject, 'movementSpeedCap').min(0.0).max(5000.0).step(1)
  .name('speed limit');

groundTweaks
  .add(groundMaterial.uniforms.uTowerHeight, 'value')
  .min(-50.0)
  .max(100.0)
  .step(1.0)
  .name('tower height');
groundTweaks
  .add(groundMaterial.uniforms.uGroundHeight, 'value')
  .min(-50.0)
  .max(50.0)
  .step(1.0)
  .name('ground height');

// Sun Plane
const sunGeometry = new THREE.CircleBufferGeometry(
  debugObject.worldSize * debugObject.sunSize,
  debugObject.worldSize * debugObject.sunSize,
  0,
  Math.PI,
);
const sunMaterial = new THREE.ShaderMaterial({
  vertexShader: sunVertexShader,
  fragmentShader: sunFragmentShader,
  side: THREE.DoubleSide,
  uniforms: {
    uColor: { value: new THREE.Color(debugObject.sunColor) },
    uPosition: { value: debugObject.position },
  },
});
const sun = new THREE.Mesh(sunGeometry, sunMaterial);
sun.position.z = debugObject.worldSize * -1.5 - 1;
scene.add(sun);
sunTweaks.add(debugObject, 'sunBaseSpeed').min(0.0).max(200.0).step(1)
  .name('base speed');
sunTweaks
  .add(debugObject, 'sunSize')
  .min(0.0)
  .max(5.0)
  .step(0.1)
  .name('scale')
  .onChange(() => {
    sun.scale.set(debugObject.sunSize, debugObject.sunSize, 1);
  });
sunTweaks
  .addColor(debugObject, 'sunColor')
  .name('color')
  .onChange(() => {
    sunMaterial.uniforms.uColor.value.set(debugObject.sunColor);
  });

/**
 * Buildings
 */
const buildings = [
  // building 1 basic sky scraper
  {
    path: [
      [0, 8],
      [2.5, 8],
      [2.5, 0],
    ],
    startingPoint: [-10, 0],
  },

  // building 2 unequal angles
  {
    path: [
      [0, 2],
      [3.5, 4],
      [5, 2],
      [5, 0],
    ],
    startingPoint: [-6, 1.5],
  },

  // building 3 pointy
  {
    path: [
      [0, 0],
      [0, 9],
      [2, 6],
      [2, 0],
    ],
    startingPoint: [-7, 0],
  },

  // building 4 saucer
  {
    path: [
      [0, 0],
      [0, 5],
      [-1.5, 5],
      [-2, 5.5],
      [-1.5, 6],
      [2.5, 6],
      [3, 5.5],
      [2.5, 5],
      [1, 5],
      [1, 0],
    ],
    startingPoint: [-2.25, 0],
  },

  // building 5 two roofs
  {
    path: [
      [0, 0],
      [0, 4],
      [2, 4],
      [5, 6],
      [6, 6],
      [6, 0],
    ],
    startingPoint: [-1, 0],
  },

  // building 6 triangle
  {
    path: [
      [0, 0],
      [3, 10],
      [6, 0],
    ],
    startingPoint: [6, 0],
  },

  // building 7 flat and low
  {
    path: [
      [0, 0],
      [0, 2],
      [3, 3],
      [7, 3],
      [10, 2],
      [10, 0],
    ],
    startingPoint: [0, 1.5],
  },
];
const extrudeSettings = {
  depth: 1,
  bevelEnabled: false,
};
const buildingGroup = new THREE.Group();
// draw the buildings and add them to the scene
for (let i = 0; i < buildings.length; i += 1) {
  // Create the shape starting at 0,0
  const shape = new THREE.Shape();
  shape.moveTo(0, 0);
  for (let j = 0; j < buildings[i].path.length; j += 1) {
    shape.lineTo(buildings[i].path[j][0], buildings[i].path[j][1]);
  }
  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

  // Create the material with a random color
  const randomColor = `#${Math.random().toString(16).substr(2, 6)}`;
  const buildingMaterial = new THREE.ShaderMaterial({
    vertexShader: buildingVertexShader,
    fragmentShader: buildingFragmentShader,
    side: THREE.DoubleSide,
    uniforms: {
      uTime: { value: 0 },
      uColor: { value: new THREE.Color(randomColor) },
      uEdgeColor: { value: new THREE.Color(debugObject.buildingGridColor) },
      uBuildingRandomSeed: { value: Math.floor(1 + Math.random() * 10) },
    },
  });

  // create the mesh and position as defined above but at the end of the world stage
  const mesh = new THREE.Mesh(geometry, buildingMaterial);
  mesh.position.set(
    buildings[i].startingPoint[0],
    -0.1,
    buildings[i].startingPoint[1] - debugObject.worldSize * 1.5 + 1,
  );
  buildings[i].mesh = mesh;

  // Add it to the scene
  buildingGroup.add(mesh);
}
scene.add(buildingGroup);

buildingTweaks
  .add(debugObject, 'buildingLightChangeRate')
  .min(0.0)
  .max(10.0)
  .step(0.1)
  .name('light frequency');
buildingTweaks
  .add(debugObject, 'buildingScale')
  .min(0.0)
  .max(10.0)
  .step(0.1)
  .name('scale')
  .onChange(() => {
    buildingGroup.scale.set(debugObject.buildingScale, debugObject.buildingScale, 1);
  });
buildingTweaks
  .addColor(debugObject, 'buildingGridColor')
  .name('grid color')
  .onChange(() => {
    for (let i = 0; i < buildings.length; i += 1) {
      buildings[i].mesh.material.uniforms.uEdgeColor.value.set(debugObject.buildingGridColor);
    }
  });

/**
 * Camera, Renderer, & Controls
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  debugObject.cemeraFOV,
  sizes.width / sizes.height,
  0.01,
  1000,
);
camera.position.set(20, 10, debugObject.worldSize * 2.25);
scene.add(camera);
cameraTweaks
  .add(debugObject, 'cemeraFOV')
  .min(debugObject.minFOV)
  .max(debugObject.maxFOV)
  .step(1)
  .name('fov')
  .onChange(() => {
    camera.fov = debugObject.cemeraFOV;
    camera.updateProjectionMatrix();
  })
  .listen();

/**
 * Audio
 */
const listener = new THREE.AudioListener();
camera.add(listener);

const bikeSound = new THREE.PositionalAudio(listener);

// load a sound and set it as the PositionalAudio object's buffer
const audioLoader = new THREE.AudioLoader();
audioLoader.load(
  `${document.URL.substring(0, document.URL.lastIndexOf('/'))}/assets/audio/motorcycle.ogg`,
  (buffer) => {
    bikeSound.setBuffer(buffer);
    bikeSound.setRefDistance(10);
    bikeSound.setLoop(true);
    bikeSound.setVolume(1.0);
  },
);

/**
 * Player Models
 */

// make a light to light the model
const ambientLight = new THREE.AmbientLight(0x404040);
scene.add(ambientLight);

// Load the model
const gltfLoader = new GLTFLoader();
let bikeModel = null;
gltfLoader.load(
  `${document.URL.substring(0, document.URL.lastIndexOf('/'))}/assets/models/bike.glb`,
  (gltf) => {
    bikeModel = gltf.scene;

    // size it, turn it, and position it
    bikeModel.scale.set(1.5, 1.5, 1.5);
    bikeModel.rotation.y = Math.PI;
    bikeModel.rotation.x -= 0.042;
    bikeModel.position.set(0, -1.715, debugObject.worldSize * 1.5 - 22);
    bikeModel.add(bikeSound);

    scene.add(bikeModel);
  },
  () => {},
  (error) => {
    console.error('gltf error:');
    console.error(error);
  },
);

/**
 * Player Controls
 */

// Key Event State Handlers
let isUpPressed = false;
let isDownPressed = false;
let isLeftPressed = false;
let isRightPressed = false;
const onKeyDown = (event) => {
  const keyCode = event.which;
  if (keyCode === 87 || keyCode === 38) {
    // Up and W
    isUpPressed = true;
  } else if (keyCode === 83 || keyCode === 40) {
    // Down and S
    isDownPressed = true;
  } else if (keyCode === 65 || keyCode === 37) {
    // Left and A
    isLeftPressed = true;
  } else if (keyCode === 68 || keyCode === 39) {
    // Right and D
    isRightPressed = true;
  } else if (keyCode === 32) {
    // play/pause music Space or M
    playPauseMusic();
  } else if (keyCode === 77) {
    muteButton.click();
  }
};
const onKeyUp = (event) => {
  const keyCode = event.which;
  if (keyCode === 87 || keyCode === 38) {
    // Up and W
    isUpPressed = false;
  } else if (keyCode === 83 || keyCode === 40) {
    // Down and S
    isDownPressed = false;
  } else if (keyCode === 65 || keyCode === 37) {
    // Left and A
    isLeftPressed = false;
  } else if (keyCode === 68 || keyCode === 39) {
    // Right and D
    isRightPressed = false;
  }
};
document.addEventListener('keydown', onKeyDown, false);
document.addEventListener('keyup', onKeyUp, false);

// what we do when the user tells us to do something
const respondToUserInput = () => {
  const movementPercentage = debugObject.movementSpeed / debugObject.movementSpeedCap;
  // Up
  if (isUpPressed) {
    if (debugObject.movementSpeed < debugObject.movementSpeedCap) {
      debugObject.movementSpeed += 0.25 * debugObject.joystickForce;
    } else {
      debugObject.movementSpeed = debugObject.movementSpeedCap;
    }
    bikeSound.setPlaybackRate(Math.max(movementPercentage * 5, 0.5));
  }
  // Down
  if (isDownPressed) {
    if (debugObject.movementSpeed > 0) {
      debugObject.movementSpeed -= debugObject.joystickForce;
    } else {
      debugObject.movementSpeed = 0;
    }
    bikeSound.setPlaybackRate(Math.max(movementPercentage * 5, 0.5));
  }

  // Reset the rotation if the player is no longer leaning
  if (!isRightPressed && !isLeftPressed && bikeModel.rotation.z !== 0) {
    const prevZ = bikeModel.rotation.z;
    bikeModel.rotation.z += bikeModel.rotation.z > 0 ? -0.1 : 0.1;
    if ((prevZ <= 0 && bikeModel.rotation.z > 0) || (prevZ >= 0 && bikeModel.rotation.z < 0)) {
      bikeModel.rotation.z = 0;
    }
  } else {
    // Left and A
    if (isLeftPressed) {
      bikeModel.rotation.z -= bikeModel.rotation.z > -Math.PI * 0.1 ? 0.04 : 0;
      if (bikeModel.position.x >= -7 && debugObject.movementSpeed !== 0) {
        bikeModel.position.x -= 0.2 * Math.min(debugObject.movementSpeed * 0.05, 1.5);
      }
      if (bikeModel.position.x <= -7) {
        bikeModel.position.x = -7;
      }
    }
    // Right and D
    if (isRightPressed) {
      bikeModel.rotation.z += bikeModel.rotation.z < Math.PI * 0.1 ? 0.04 : 0;
      if (bikeModel.position.x <= 7 && debugObject.movementSpeed !== 0) {
        bikeModel.position.x += 0.2 * Math.min(debugObject.movementSpeed * 0.05, 1.5);
      }
      if (bikeModel.position.x >= 7) {
        bikeModel.position.x = 7;
      }
    }
  }
};

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.autoRotateSpeed *= 0.025;
controls.addEventListener('start', () => {
  controls.autoRotate = false;
});
controls.target = new THREE.Vector3(0, sun.position.y + 5, 0);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({ canvas });
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ReinhardToneMapping;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Post Processing
 */
// Render target
let RenderTargetClass = null;
if (renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2) {
  RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
  console.warn('WebGL 2 not supported, using WebGLMultisampleRenderTarget');
} else {
  RenderTargetClass = THREE.WebGLRenderTarget;
  console.warn('WebGL 2 supported, using WebGLMultisampleRenderTarget');
}
const renderTarget = new RenderTargetClass(sizes.width, sizes.height, {
  minFilter: THREE.LinearFilter,
  magFilter: THREE.LinearFilter,
  format: THREE.RGBAFormat,
  encoding: THREE.sRGBEncoding,
});

// composer and bloom effect
const effectComposer = new EffectComposer(renderer, renderTarget);
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
effectComposer.setSize(sizes.width, sizes.height);

const renderPass = new RenderPass(scene, camera);
effectComposer.addPass(renderPass);

const unrealBloomPass = new UnrealBloomPass();
unrealBloomPass.enabled = true;
unrealBloomPass.strength = 0.75;
unrealBloomPass.radius = 0.5;
unrealBloomPass.threshold = 0.2;
effectComposer.addPass(unrealBloomPass);

bloomTweaks.add(unrealBloomPass, 'enabled');
bloomTweaks.add(unrealBloomPass, 'strength').min(0).max(2).step(0.001);
bloomTweaks.add(unrealBloomPass, 'radius').min(0).max(2).step(0.001);
bloomTweaks.add(unrealBloomPass, 'threshold').min(0).max(2).step(0.001);

/**
 * Animate
 */
const clock = new THREE.Clock();
let deltaTime = 0;
let oldElapsedTime = 0;
const speedometer = document.getElementById('speedometer');

const tick = () => {
  // Figure out our numbers
  const elapsedTime = clock.getElapsedTime();
  deltaTime = elapsedTime - oldElapsedTime;
  oldElapsedTime = elapsedTime;

  // update position state
  debugObject.position += deltaTime * debugObject.movementSpeed;

  // Update Materials
  groundMaterial.uniforms.uPosition.value = debugObject.position;
  sunMaterial.uniforms.uPosition.value
    += deltaTime * debugObject.movementSpeed * 0.005 + deltaTime * debugObject.sunBaseSpeed * 0.01;

  // update building shaders
  for (let i = 0; i < buildings.length; i += 1) {
    buildings[i].mesh.material.uniforms.uTime.value
      += deltaTime * debugObject.buildingLightChangeRate;
  }

  // update the bike model
  if (bikeModel !== null) {
    // change the tail length based on movement speed
    const scale = debugObject.movementSpeed < 15 ? (debugObject.movementSpeed * 11) / 24 : 6.66;
    if (bikeModel.children[5].scale.z !== scale) {
      bikeModel.children[5].scale.z = scale;
      bikeModel.children[6].scale.y = scale;
      bikeModel.children[7].scale.y = scale;
      bikeModel.children[5].position.z = -scale - 1.5;
      bikeModel.children[6].position.z = -scale - 1.5;
      bikeModel.children[7].position.z = -scale - 1.5;
    }

    // Update character movement
    respondToUserInput();
  }

  // update the speedometer
  speedometer.innerHTML = String(Math.floor(debugObject.movementSpeed)).padStart(2, '0');

  // shift the sky
  globe.rotation.x += (deltaTime * debugObject.starSpeed) / 100 + debugObject.movementSpeed / 50000;

  // Update controls
  controls.update();

  // Render
  effectComposer.render();

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

/** Mobile Joystick */
const joystickOptions = {
  zone: document.getElementById('joystick'),
  mode: 'static',
  restOpacity: 0.6,
  position: {
    right: '5em',
    bottom: '5em',
  },
};

const bikeJoy = nipplejs.create(joystickOptions);

bikeJoy.on('move dir start', (evt, data) => {
  if (data.direction && data.distance) {
    isRightPressed = data.direction.x === 'right'
      && data.distance > 20
      && (data.angle.degree > 290 || data.angle.degree < 70);
    isLeftPressed = data.direction.x === 'left'
      && data.distance > 20
      && data.angle.degree < 250
      && data.angle.degree > 110;
    isUpPressed = data.direction.y === 'up'
      && data.distance > 20
      && data.angle.degree > 40
      && data.angle.degree < 140;
    isDownPressed = data.direction.y === 'down'
      && data.distance > 20
      && data.angle.degree > 200
      && data.angle.degree < 340;
    debugObject.joystickForce = data.force * 0.5;
  }
});

bikeJoy.on('end', () => {
  isLeftPressed = false;
  isRightPressed = false;
  isDownPressed = false;
  isUpPressed = false;
  debugObject.joystickForce = 1;
});

const gameStartAudio = new Audio('assets/audio/start.wav');
gameStartAudio.volume = 0.4;
document.addEventListener(
  'DOMContentLoaded',
  () => {
    const startButton = document.getElementById('start-button');
    startButton.onclick = () => {
      gameStartAudio.play();
      controls.autoRotate = true;
      const loadingOverlay = document.getElementById('loading-things');
      loadingOverlay.style.opacity = 0;
      setTimeout(() => {
        loadingOverlay.parentNode.removeChild(loadingOverlay);
      }, 2000);

      const gameThings = document.getElementById('game-things');
      gameThings.classList.add('show');
      setTimeout(() => {
        playPauseMusic();
        bikeSound.play();
      }, 500);
    };
    setTimeout(() => {
      startButton.classList.add('show');
    }, 1000);
  },
  false,
);

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
